import { useContext } from 'react';
import { Box, Button, Divider, InlineStack, TextField } from '@shopify/polaris';
import { DeleteIcon } from '@shopify/polaris-icons';

import { ResourceInfo } from '@/shared/links/ResourceInfo';
import { PermissionsContext } from '../../pages/SingleSubscription';
import { t } from 'react-simple-multi-language';
import { UpdateSubscriptionLine } from 'types/SubscriptionContract';
import { FillStackItem } from '@/shared/FillStackItems';

import { ShortVariantTempLine } from 'hooks/useEditSubscriptionLine';

type Props = {
  item: ShortVariantTempLine;
  itemsCount: number;
  updateLine: (variantId: number, quantity: number) => void;
  removeLines: (idsToRemove: number[]) => void;
  swapLine: (variantId: number) => void;
};

const ContractItemRow = ({ item, itemsCount, swapLine, updateLine, removeLines }: Props) => {
  const { permissions } = useContext(PermissionsContext);

  const handleUpdateFields = (value: string, key: keyof UpdateSubscriptionLine) => {
    updateLine(item.id, Number(value));
  };

  return (
    <>
      <Box paddingBlock="500">
        <InlineStack blockAlign="center" gap="400" wrap={false}>
          <FillStackItem>
            <ResourceInfo
              resource={{
                title: item?.product_title,
                subtitle: `${item?.title || ''}`,
                image: item?.image_src,
              }}
            />
          </FillStackItem>

          {!!permissions.change_quantity && (
            <div style={{ width: 70 }}>
              <TextField
                label={t('quantity')}
                autoComplete="off"
                id="quantity"
                type="number"
                min="1"
                value={String(item.quantity)}
                onChange={handleUpdateFields}
              />
            </div>
          )}
          {!!permissions.add_subscription_line && (
            <div style={{ transform: 'translateY(10px)' }}>
              <Button variant="plain" onClick={() => swapLine(item.id)}>
                Swap
              </Button>
            </div>
          )}
          {!!permissions.remove_subscription_line && !!itemsCount && (
            <div style={{ transform: 'translateY(12px)' }}>
              <Button onClick={() => removeLines([item.id])} icon={DeleteIcon} variant="monochromePlain" />
            </div>
          )}
        </InlineStack>
      </Box>
      <Divider />
    </>
  );
};

export default ContractItemRow;
