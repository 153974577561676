import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Modal } from '@shopify/polaris';

import { Product, ProductVariant, ShortProduct, ShortProductVariant } from 'types/Product';

import ProductRow from './ProductRow';
import Loader from '@/shared/loaders/Loader';
import { t } from 'react-simple-multi-language';
import { useShowModalOnTop } from 'hooks/useShowModalOnTop';

type props = {
  openProductModal: boolean;
  toggleProductModal: () => void;
  products: Product[];
  productsLoading: boolean;
  selectedProducts: ShortProduct[];
  selectedProductVariants: ShortProductVariant[];
  renderProductVariants?: boolean;
  updateSelectedProducts: (newSelectedProducts: ShortProduct[], newSelectedVariants: ShortProductVariant[]) => void;
  handleLoadMoreProducts: () => void;
  isSingleSelection?: boolean;
};

export const ProductSelectionModal = ({
  openProductModal,
  toggleProductModal,
  products,
  productsLoading,
  selectedProducts,
  selectedProductVariants,
  renderProductVariants = true,
  updateSelectedProducts,
  handleLoadMoreProducts,
  isSingleSelection,
}: props) => {
  const modalWrapperRef = useRef<HTMLDivElement | null>(null);

  useShowModalOnTop(modalWrapperRef, [openProductModal]);

  // const debounceSetProductFilter = useDebounceAction(modifyProductFilter, 500);

  const [currentSelectedProducts, setCurrentSelectedProducts] = useState(selectedProducts);
  const [currentSelectedProductVariants, setCurrentSelectedProductVariants] = useState(selectedProductVariants);
  const selectedProductIds = currentSelectedProducts?.map((product) => product?.id);
  const selectedVariantIds = currentSelectedProductVariants?.map((variant) => variant?.id);

  // const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    setCurrentSelectedProducts(selectedProducts);
    setCurrentSelectedProductVariants(selectedProductVariants);
  }, [selectedProducts, selectedProductVariants]);

  const cancelModal = useCallback(() => {
    setCurrentSelectedProducts(selectedProducts);
    setCurrentSelectedProductVariants(selectedProductVariants);
    toggleProductModal();
  }, [toggleProductModal, selectedProducts, selectedProductVariants]);

  // const handleSearchValue = useCallback(
  //   (value: string) => {
  //     setSearchValue(value);
  //     debounceSetProductFilter({ search: value });
  //   },
  //   [debounceSetProductFilter]
  // );

  const handleProductCheck = useCallback(
    (isChecked: boolean, id: string) => {
      const targetProduct = products?.find((product) => product?.id === Number(id));
      const targetProductVariants = targetProduct?.variants;
      const targetProductVariantsIds = targetProductVariants?.map((variant) => variant?.id);

      if (targetProduct) {
        if (isChecked) {
          setCurrentSelectedProducts((currentSelectedProducts) => [...currentSelectedProducts, targetProduct]);
        } else {
          if (
            !targetProductVariants?.every((variant) => selectedVariantIds?.includes(variant?.id)) &&
            targetProductVariants?.some((variant) => selectedVariantIds?.includes(variant?.id))
          ) {
            setCurrentSelectedProducts((currentSelectedProducts) => [...currentSelectedProducts, targetProduct]);
            setCurrentSelectedProductVariants((currentSelectedProductVariants) => {
              return currentSelectedProductVariants?.filter(
                (variant) => !targetProductVariantsIds?.includes(variant?.id)
              );
            });
          } else {
            setCurrentSelectedProducts((currentSelectedProducts) => {
              return currentSelectedProducts?.filter((product) => product?.id !== targetProduct?.id);
            });
          }
        }
      }
    },
    [products, selectedVariantIds]
  );

  const handleVariantCheck = (isChecked: boolean, variantId: string, productId: string) => {
    const targetProduct = products?.find((product) => product?.id === Number(productId)) as Product;
    const targetProductVariantsExceptCurrentVariant = targetProduct?.variants
      ?.filter((variant) => {
        return variant?.id !== Number(variantId);
      })
      ?.map<ShortProductVariant>((variant) => {
        return {
          ...variant,
          product_title: targetProduct.title,
          image_src: variant.image_src || targetProduct.image_src,
        };
      });
    const targetProductVariantsExceptCurrentVariantIds = targetProductVariantsExceptCurrentVariant?.map(
      (variant) => variant?.id
    );
    const targetVariant: ProductVariant = {
      ...(targetProduct?.variants?.find((variant) => variant?.id === Number(variantId)) as ProductVariant),
      product: targetProduct,
    };
    const allTheOtherVariantsAreAlreadySelected = targetProductVariantsExceptCurrentVariant?.every((variant) =>
      selectedVariantIds?.includes(variant?.id)
    );

    if (targetProduct) {
      if (isSingleSelection) {
        setCurrentSelectedProductVariants([
          {
            ...targetVariant,
            product_title: targetProduct.title,
            image_src: targetVariant.image_src || targetProduct.image_src,
          },
        ]);
        return;
      }

      if (isChecked) {
        if (allTheOtherVariantsAreAlreadySelected) {
          setCurrentSelectedProducts((currentSelectedProducts) => [...currentSelectedProducts, targetProduct]);
          setCurrentSelectedProductVariants((currentSelectedProductVariants) => {
            return currentSelectedProductVariants?.filter(
              (variant) => !targetProductVariantsExceptCurrentVariantIds?.includes(variant?.id)
            );
          });
        } else {
          setCurrentSelectedProductVariants((currentSelectedProductVariants) => [
            ...currentSelectedProductVariants,
            {
              ...targetVariant,
              product_title: targetProduct.title,
              image_src: targetVariant.image_src || targetProduct.image_src,
            },
          ]);
        }
      } else {
        if (selectedProductIds?.includes(Number(productId))) {
          setCurrentSelectedProducts((currentSelectedProducts) =>
            currentSelectedProducts?.filter((product) => product?.id !== Number(productId))
          );
          setCurrentSelectedProductVariants((currentSelectedProductVariants) => [
            ...currentSelectedProductVariants,
            ...targetProductVariantsExceptCurrentVariant,
          ]);
        } else {
          setCurrentSelectedProductVariants((currentSelectedProductVariants) => {
            return currentSelectedProductVariants?.filter((variant) => variant?.id !== Number(variantId));
          });
        }
      }
    }
  };

  const addProducts = useCallback(() => {
    updateSelectedProducts(currentSelectedProducts, currentSelectedProductVariants);
    toggleProductModal();
  }, [toggleProductModal, updateSelectedProducts, currentSelectedProductVariants, currentSelectedProducts]);

  return (
    <Modal
      open={openProductModal}
      onClose={cancelModal}
      title={t('all_products')}
      limitHeight
      primaryAction={{
        content: isSingleSelection ? 'Swap' : t('update'),
        onAction: addProducts,
      }}
      secondaryActions={[{ content: t('button_cancel'), onAction: cancelModal }]}
      onScrolledToBottom={handleLoadMoreProducts}
    >
      <div ref={modalWrapperRef}>
        {/* <Modal.Section>
        <TextField
          autoComplete="off"
          label={undefined}
          // todo:content
          placeholder={'Search products'}
          prefix={<Icon source={SearchIcon} />}
          value={searchValue}
          onChange={handleSearchValue}
        />
      </Modal.Section> */}

        {products.length ? (
          products?.map((product) => (
            <ProductRow
              renderProductVariants={renderProductVariants}
              key={product?.id}
              product={product}
              selectedProductIds={selectedProductIds}
              selectedVariantIds={selectedVariantIds}
              handleProductCheck={handleProductCheck}
              handleVariantCheck={handleVariantCheck}
              isSingleSelection={isSingleSelection}
            />
          ))
        ) : (
          <p style={{ textAlign: 'center', margin: '20px 0' }}>there is no product to show</p>
        )}

        {productsLoading && (
          <Modal.Section>
            <Loader />
          </Modal.Section>
        )}
      </div>
    </Modal>
  );
};
