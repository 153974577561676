import { useEffect, useState } from 'react';
import { SubscriptionContractLine, UpdateSubscriptionLine } from 'types/SubscriptionContract';
import { ShortProductVariant } from 'types/Product';
import { SetFormField } from 'types/TypeUtils';

type VariantTempLine = ShortProductVariant & { quantity: number; selling_plan_id?: number };
export type ShortVariantTempLine = Pick<
  VariantTempLine,
  'id' | 'image_src' | 'quantity' | 'product_title' | 'selling_plan_id'
> & {
  variant_title?: string | null;
  title: string;
};

type SavedVariantLine = ShortVariantTempLine & {
  line_id: string;
};

type Data = {
  variants_to_add: Required<VariantTempLine>[];
  lines_to_remove: string[];
  lines_to_update: UpdateSubscriptionLine[];
};

type SwapConfig = {
  prevVariantId: number;
  newVariant: VariantTempLine;
};

const initialData = {
  variants_to_add: [],
  lines_to_remove: [],
  lines_to_update: [],
};

export const useEditSubscriptionLine = (initialLines: SavedVariantLine[]) => {
  const [data, setData] = useState<Data>(initialData);
  console.log(data);
  const setField: SetFormField<typeof data> = (value, key) => {
    setData((fields) => {
      return { ...fields, [key]: typeof value === 'function' ? value(fields[key]) : value };
    });
  };

  const getLineFromVariantId = (variantId: number) => {
    const tempLine = data.variants_to_add.find((variant) => variant.id === variantId);
    const savedLine = initialLines.find((line) => line.id === variantId);

    const isSavedLineRemoving = data.lines_to_remove.includes(String(savedLine?.id));

    return { savedLine: isSavedLineRemoving ? null : savedLine, tempLine };
  };

  useEffect(() => {
    setData(initialData);
  }, [initialLines]);

  const removeLines = (variantIds: number[]) => {
    const savedLines: string[] = [];
    const tempLines: VariantTempLine[] = [];

    variantIds.forEach((id) => {
      const { savedLine, tempLine } = getLineFromVariantId(id);
      if (savedLine || tempLine) savedLine ? savedLines.push(String(savedLine.line_id)) : tempLines.push(tempLine!);
    });

    setData((prev) => ({
      lines_to_remove: [...prev.lines_to_remove, ...savedLines],
      variants_to_add: prev.variants_to_add.filter((variant) => !tempLines.find((temp) => variant.id === temp.id)),
      lines_to_update: prev.lines_to_update.filter(
        (updatingLine) => !savedLines.find((savedLineId) => updatingLine.id === savedLineId)
      ),
    }));
  };

  const getSellingPlanId = (variantId?: number) => {
    let id = initialLines[0]?.selling_plan_id;
    if (variantId) {
      const { savedLine } = getLineFromVariantId(variantId);
      if (savedLine) id = savedLine.selling_plan_id;
    }

    return id;
  };

  const addLine = (variants: (ShortProductVariant | VariantTempLine)[]) => {
    setField(
      [
        ...data.variants_to_add,
        ...variants.map((variant) => {
          const sellingPlanId = (variant as ShortVariantTempLine).selling_plan_id || getSellingPlanId()!;
          return { quantity: 1, selling_plan_id: sellingPlanId, ...variant };
        }),
      ],
      'variants_to_add'
    );
  };

  const updateLine = (variantId: number, quantity: number) => {
    const { savedLine, tempLine } = getLineFromVariantId(variantId);

    if (savedLine) {
      setField(
        (prev) => [...prev.filter((line) => line.id !== savedLine.line_id), { id: savedLine.line_id, quantity }],
        'lines_to_update'
      );
    } else {
      setField(
        (prev) => prev.map((variant) => (variant.id === tempLine?.id ? { ...variant, quantity } : variant)),
        'variants_to_add'
      );
    }
  };

  const swapLines = ({ newVariant, prevVariantId }: SwapConfig) => {
    removeLines([prevVariantId]);
    addLine([{ ...newVariant, selling_plan_id: getSellingPlanId(prevVariantId) }]);
  };

  const clearTempData = () => setData(initialData);

  const savedLinesList = initialLines
    .filter((line) => !data.lines_to_remove.find((lineId) => String(line.line_id) === lineId))
    .map((line) => {
      const updatedLine = data.lines_to_update.find((updateLine) => String(line.line_id) === updateLine.id);
      return updatedLine ? { ...line, ...updatedLine } : line;
    });

  const linesList = (
    [...savedLinesList, ...data.variants_to_add] as (SubscriptionContractLine & VariantTempLine)[]
  ).map<ShortVariantTempLine>((line) => ({
    id: line.variant_id || line.id,
    image_src: line.image_src || line.variant_image_src,
    quantity: line.quantity,
    title: line.title,
    variant_title: line.variant_title,
    product_title: line.product_title,
  }));

  const updateLinesFromVariantList = (variants: ShortProductVariant[]) => {
    const linesToAdd: ShortProductVariant[] = [];
    const linesToRemove: number[] = [];

    linesList.forEach((variant) => {
      const shouldRemove = !variants.find((v) => v.id === variant.id);

      if (shouldRemove) linesToRemove.push(variant.id);
    });

    variants.forEach((variant) => {
      const { savedLine, tempLine } = getLineFromVariantId(variant.id);

      if (!savedLine && !tempLine) {
        linesToAdd.push(variant);
      }
    });

    addLine(linesToAdd);
    removeLines(linesToRemove);
  };

  const getUpdateLinesData = () => {
    const { lines_to_remove, lines_to_update, variants_to_add } = data;

    return {
      lines_to_remove,
      lines_to_update,
      lines_to_add: variants_to_add.map((variant) => ({
        discount_percentage: 0,
        product_variant_id: variant.id,
        quantity: variant.quantity,
        selling_plan_id: variant.selling_plan_id,
      })),
    };
  };

  return {
    swapLines,
    linesList,
    addLine,
    removeLines,
    updateLine,
    clearTempData,
    updateLinesFromVariantList,
    getUpdateLinesData,
  };
};
