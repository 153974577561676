import { InlineStack, Link, Text } from '@shopify/polaris';
import { EXTERNAL_URLS, SUBIFY_APP_LISTING_URL } from 'config/constants';

export const LoginFormLinks = () => {
  return (
    <InlineStack align="space-between">
      <p>
        <Text as="p" variant="bodyMd" tone="subdued">
          Powered by{' '}
          <Link url={SUBIFY_APP_LISTING_URL} removeUnderline external>
            Subi
          </Link>
        </Text>
      </p>
      <Link url={EXTERNAL_URLS.LEARN_MORE.PORTAL_LOGIN} removeUnderline external>
        <Text as="p" variant="bodyMd" tone="subdued">
          Help
        </Text>
      </Link>
    </InlineStack>
  );
};
