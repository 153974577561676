export const DEFAULT_BASE_URL = 'https://api-beta.subi.co';
export const TABLE_ROWS_PER_PAGE = 6;

export const SUBIFY_APP_LISTING_URL = 'https://subi.co';

export const PATHS = {
  LOGIN: '/login/',
  SUBSCRIPTIONS: '/',
};

export const EXTERNAL_URLS = {
  LEARN_MORE: {
    PORTAL_LOGIN: 'https://help.subi.co/en/articles/8009854-customer-portal-passwordless-login',
  },
};
